import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Columns } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { ComponentLinks } from '../common';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "columns"
    }}>{`Columns`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Columns } from '@hse-design/react';
`}</code></pre>
    <ComponentLinks figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=9475%3A98'} storybook={'/?path=/story/content-content--playground'} vue={'/components/HseColumns/HseColumns.html'} vueStorybook={'/?path=/story/content-content--playground'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`Columns – это компонент для создания двухколоночного текста. Текст, переданный в `}<inlineCode parentName="p">{`children`}</inlineCode>{`, делится поровну и размещается в двух колонках.`}</p>
    <p>{`При использовании контентного варианта (`}<inlineCode parentName="p">{`ContentColumns`}</inlineCode>{`) либо свойства `}<inlineCode parentName="p">{`hasMargin`}</inlineCode>{` добавляются вертикальные отступы.
Это полезно при реализации контентных страниц.`}</p>
    <Playground __position={1} __code={'<Columns>\n  Можно заметить, что цифры находятся в отдельном компоненте, который на 8\n  пикселей левее текста. Далее продолжается абзац прежнего текста. По своей\n  сути рыбатекст является альтернативой традиционному lorem ipsum, который\n  вызывает у некторых людей недоумение при попытках прочитать рыбу текст. В\n  отличии от lorem ipsum, текст рыба на русском языке наполнит любой макет\n  непонятным смыслом и придаст колорит советских времен.\n</Columns>'} __scope={{
      props,
      DefaultLayout,
      Columns,
      Playground,
      Props,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Columns mdxType="Columns">
    Можно заметить, что цифры находятся в отдельном компоненте, который на 8
    пикселей левее текста. Далее продолжается абзац прежнего текста. По
    своей сути рыбатекст является альтернативой традиционному lorem ipsum,
    который вызывает у некторых людей недоумение при попытках прочитать рыбу
    текст. В отличии от lorem ipsum, текст рыба на русском языке наполнит
    любой макет непонятным смыслом и придаст колорит советских времен.
  </Columns>
    </Playground>
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={Columns} mdxType="Props" />
    <p>{`Все остальные свойства пробрасываются на корневой элемент `}<inlineCode parentName="p">{`div`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      